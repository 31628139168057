import { set } from "../constant/actionTypes";

const initialState = {
    sidebarShow: true,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case set:
            return { ...state, sidebarShow: action.data }
        default:
            return state
    }
}
