import { SET_ACTSTATUS, SET_SUBMIT_STATUS } from "../constant/actionTypes";

const initial_state = {
  actStatus: [],
  submitStatus: false,
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_ACTSTATUS:
      return { ...state, actStatus: action.data };

    case SET_SUBMIT_STATUS:
      return { ...state, submitStatus: action.data };
    default:
      return { ...state };
  }
};
