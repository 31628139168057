export const set = "set";

export const SET_LOGIN_FLAG = "SET_LOGIN_FLAG";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_AUTHTOKEN = "SET_USER_AUTHTOKEN";
export const SET_USER_ROLE = "SET_USER_ROLE";
export const USER_LOGOUT = "USER_LOGOUT";

export const SET_USERS = "SET_USERS";
export const SET_ORDERS = "SET_ORDERS"
export const SET_ACTSTATUS = "SET_ACTSTATUS"
export const SET_SUBMIT_STATUS = "SET_SUBMIT_STATUS"